<template>
  <b-card>
    <form @submit.prevent="validateBeforeSubmit">
      <h1>Edit Profile</h1>

      <p class="text-muted">Enter admin information</p>
      <label for="">First Name</label>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
        </b-input-group-prepend>
        <b-form-input type="text" v-validate="'required'" name="fname" v-model="fname" class="form-control" placeholder="First Name" />
      </b-input-group>
      <i v-show="errors.has('fname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('fname')" class="help is-danger">The first name is required.</span>
      <label for="">Last Name</label>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
        </b-input-group-prepend>
        <b-form-input type="text" v-validate="'required'" name="lname" v-model="lname" class="form-control" placeholder="Last Name" />
      </b-input-group>
      <i v-show="errors.has('lname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('lname')" class="help is-danger">The last name is required.</span>
      <hr>

      <label for="">Email</label>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-input-group-text><i class="fa fa-envelope"></i></b-input-group-text>
        </b-input-group-prepend>
        <b-form-input type="email" v-model="email" v-validate="'required'" name="email" class="form-control" placeholder="Email" />
      </b-input-group>
      <i v-show="errors.has('email')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('email')" class="help is-danger">The email is required.</span>
      <i v-show="error" class="fa fa-exclamation-triangle"></i><span v-show="error" class="help is-danger">Error. Please contact admin.</span>

      <b-button variant="success" class="mt-4" type="submit" block>Edit Profile</b-button>
    </form>
  </b-card>
</template>

<script>
export default {
  name: 'AdminEditProfile',
  data: function () {
    return {
      fname: '',
      lname: '',
      email: '',
      error: false,
    }
  },
  mounted: function () {
    this.$http.get('/admin/get/profile')
    .then(response => {
      if (response.status === 200) {
        this.fname = response.data.result.fname
        this.lname = response.data.result.lname
        this.email = response.data.result.email
      }
    })
    .catch(error => {
        this.error = true;
    })
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            fname: this.fname,
            lname: this.lname,
            email: this.email,
          })
          this.$http.post('/admin/edit/profile', data)
          .then(response => {
            if (response.status === 200) {
              this.$toasted.show('Successfully Updated', {icon: 'fa-check', type: 'success', duration: '3000'})
              this.$router.go(-1)
            }
          })
          .catch(error => {
            this.error = true;
          })
          return;
        }
      });
    }
  }
}
</script>
